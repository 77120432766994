// const url = 'http://localhost:8000'
// const invUrl = "http://localhost:3000";

  const url = 'https://restaurantapi.easysheba.com';
  const invUrl = "https://restaurant.easysheba.com";

  // const url = 'https://rs1api.porishebabd.com';
  // const invUrl = "https://rs1.porishebabd.com";

export default url;

export{ invUrl };