import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import url from '../../url';

function Invoice(){

    const [purchase, setPurchase] = useState({_id:'', cart:[], customer:{}, receivable:0, received:0, due:0, customerName:"", customerEmail:"", customerPhone:"", customerAddress:"", totalPrice: 0, discount:0, quantity:0, date:''});
    const {id} = useParams();
    const navigate = useNavigate();

    useEffect(()=>{

        fetch(`${url}/sale/${id}`,{credentials: 'include'}).then((data)=>data.json()).then((data)=>{

            setPurchase(data.data[0]);


            setTimeout(() => {
                window.print();
                navigate(-1)
            }, 500);
            
        });

    },[id, navigate]);




    return(
        <div className=" p-4">
            <h2 className=" text-center font-bold"> Shopno Ghuri Restaurent </h2>
            <h3 className=" text-center "> Thana Mor, Opposite Rotary Eye Hospital, Chand Mohammad Road, Thanapara, Kushtia.</h3>
            <h3 className=" text-center "><span className="font-bold ">Phone No: </span> 01825909739<span></span></h3>
            <span className=" text-left">Date: {new Date(purchase.date).toLocaleDateString()}</span> <span className=" float-right">Time: {new Date(purchase.date).toLocaleTimeString()}</span>
            <h1 className=" font-bold text-center"><span>Token:</span> <span>{purchase.token}</span></h1>
            <div className=" border-y border-dashed border-slate-400 "></div>
            <h1 className=" text-center font-bold ">Customer</h1>
            <div className=" border-b border-dashed border-slate-400 "></div>
            <div className=" flex flex-wrap gap-x-2 border-t border-dashed border-slate-400 ">
                <span className=" whitespace-nowrap"><span className="font-bold ">Name :</span> {purchase.customerName} </span> 
                {
                    (purchase.customerEmail === "")?<></>:<span className=" whitespace-nowrap"><span className="font-bold ">Email:</span> {purchase.customerEmail} </span>
                } 
                {
                    (purchase.customerPhone === "")?<></>:<span className=" whitespace-nowrap"><span className="font-bold ">Phone:</span> {purchase.customerPhone} </span>
                } 
                 
            </div>
            <h1 className=" text-center font-bold border-b-2  border-dashed border-slate-400">Paid Bill</h1>
            <table className=" w-full table-auto border-collapse ">
                        <thead>
                            <tr className=" text-left border-slate-400 border-b border-dashed">
                                <td>Item Name</td>
                                <td>Price</td>
                                <td>Vat/Tax</td>
                                <td>Quantity</td>
                                <td>Total</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                purchase.cart.map((data,index)=>{
                                    return(
                                    <tr key={index} className=" border-slate-400 border-b border-dashed">
                                    <td>{data.name}</td>
                                    <td>{data.price}  </td>
                                    <td>{data.vat?purchase.vat:0}%</td>
                                    <td>{data.quantity}</td>
                                    <td>{data.vat?((data.price * data.quantity) + ((data.price * data.quantity * purchase.vat)/100)):(data.price * data.quantity)}  ৳</td>
                                </tr>)
                                })
                            }

                            <tr >
                                <td></td>
                                <td></td>
                                <td></td>
                                <th className=" text-left">Service Charge :</th>
                                <td className=" ">{purchase.serviceCharge}  ৳</td>
                            </tr>

                            <tr >
                                <td></td>
                                <td></td>
                                <td></td>
                                <th className="  text-left">Total :</th>
                                <td className="  ">{purchase.totalPrice}  ৳</td>
                            </tr>

                            <tr className=" border-b-[24px] border-transparent "></tr>

                            <tr >
                                <td></td>
                                <td></td>
                                <td></td>
                                <th className="  text-left">Discount :</th>
                                <td className=" ">{purchase.discount}  ৳</td>
                            </tr>

                            <tr >
                                <td></td>
                                <td></td>
                                <td></td>
                                <th className="  text-left">Payable :</th>
                                <td className="  ">{purchase.totalPrice - purchase.discount}  ৳</td>
                            </tr>
                            <tr >
                                <td></td>
                                <td></td>
                                <td></td>
                                <th className=" text-left">Paid :</th>
                                <td className="  ">{purchase.payed}  ৳</td>
                            </tr>
                            {/* <tr >
                                <td></td>
                                <td></td>
                                <th className="  text-left">Due :</th>
                                <td className=" ">{purchase.due - purchase.payed - purchase.discount}  ৳</td>
                            </tr> */}
                        </tbody>
                    </table>
            <div className=" w-full h-auto mt-6 flex flex-col items-center">
                <span className=" font-bold">Powered By</span>
                <p>
                    <span>EasySheba Platform Limited</span>
                    <span className=" italic"> ( www.easysheba.com ) </span>
                </p>

            </div>
        </div>
        
    );
}

export default Invoice;