import React, {  useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Delete } from '../../components/Button';
import Table, { Tr, Td } from '../../components/Table';
import Loader from '../../components/Loader';
import { Alert1, Alert2, AlertContainer } from '../../components/Alert';
import url from '../../url';

function AllUser() {

const [category, setCategory] = useState({data:[],next:{},previous:{}});
const [src, setSrc] = useState('');
const page = useSelector((state)=>state.page);
const limit = useSelector((state)=>state.limit);
const [loader, setLoader] = useState(true);
const [alert, setAlert] = useState([]);



function delet(id){
    fetch(`${url}/user/${id}`,{method:'DELETE',mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
            setAlert((alert)=>[...alert, <Alert1 key={ Date.now()} title="Successful" message={data.message} />]);
            if(src === ''){
                setLoader(true);
                fetch(`${url}/user?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
                    setLoader(false);
                    if(data.status === true){
                        setCategory(data.result);
                    }
                });
            }else{
                setLoader(true);
                fetch(`${url}/user/search?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
                    setLoader(false);
                    if(data.status === true){
                        setCategory(data.result);
                    }
                });
                 
            }
        }else{
            setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
        }
    });
}
useEffect(()=>{
    if(src === ''){
        setLoader(true);
        fetch(`${url}/user?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
            setLoader(false);
            if(data.status === true){
                setCategory(data.result);
            }
        });
    }

    return () => setCategory({data:[],next:{},previous:{}});

},[page,src, limit]);

useEffect(()=>{
    if(src !== ''){
        fetch(`${url}/user/search?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setCategory(data.result);
            }
        });
    
    }

    return () => setCategory({data:[],next:{},previous:{}});
},[page, limit, src]);

function search(e){
    setSrc(e.target.value);
}


    return (
        <>
            <div className= " absolute top-10 right-2">
                <AlertContainer>
                    {alert}
                </AlertContainer>
            </div>
            {
                loader?<div className=' w-full flex justify-center mt-5 '><Loader /></div>:
                <Table to="/addUser" name="Add User" rowNames={["#","Name","Email","Phone","Role","Actions"]} srcVal={src} srcFunc={search} data={category} width='w-full'>
                    {
                        category.data.map(({_id, name, email, phone, role}, index)=>{
                            if(index === 0 || index === 1){
                                return(<></>);
                            }
                            return(
                                <Tr key={index}>
                                    <Td>{((category.previous.page*category.previous.limit)+1)+index-2}</Td>
                                    <Td>{name}</Td>
                                    <Td width='w-40'>{email}</Td>
                                    <Td width='w-40'>{phone}</Td>
                                    <Td width='w-40'>{role}</Td>
                                    <Td>
                                        <Delete id={_id} click={delet} />
                                    </Td>
                                </Tr>
                            );
                        })
                    }
                            
                </Table>
            }
        </>
    )
}

export default AllUser;